html {
  font-size: 10px;
}

body {
  font-size: 1.6em;
}
body.s1 {
  font-size: 1em;
}
body.s2 {
  font-size: 1.1em;
}
body.s3 {
  font-size: 1.2em;
}
body.s4 {
  font-size: 1.3em;
}
body.s5 {
  font-size: 1.4em;
}
body.s6 {
  font-size: 1.5em;
}
body.s7 {
  font-size: 1.6em;
}
body.s8 {
  font-size: 1.7em;
}
body.s9 {
  font-size: 1.8em;
}
body.s10 {
  font-size: 1.9em;
}
body.s11 {
  font-size: 2em;
}
body.s12 {
  font-size: 2.1em;
}
body.s13 {
  font-size: 2.2em;
}
body.s14 {
  font-size: 2.3em;
}
body.s15 {
  font-size: 2.4em;
}
body.s16 {
  font-size: 2.5em;
}
body.s17 {
  font-size: 2.6em;
}
body.s18 {
  font-size: 2.7em;
}
body.s19 {
  font-size: 2.8em;
}
body.s20 {
  font-size: 2.9em;
}
body.s21 {
  font-size: 3em;
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 3rem;
}

.page-wrapper {
  max-width: 1000px;
}
